import './_woocommerce-parts.scss';

import './cart/cart';
import './checkout/checkout';
import './general/general';
import './header/mini-cart/mini-cart';
import './my-account/my-account';
import './on-sale-badge/on-sale-badge';
import './product-page/product-page';
import './shop-archive/shop-archive';
import './woo-widgets/woo-widgets';