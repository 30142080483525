import "./_tabs.scss";

$(function () {
    $('.product .wc-tabs li.description_tab a').addClass('active'); 

    $('.product .wc-tabs li a').click(function (e) { 
        e.preventDefault();
        $(this).closest('ul').find('a').removeClass('active');
        $(this).addClass('active'); 
    });
});


import './additional-details/additional-details';
import './description/description';
import './reviews/reviews';
